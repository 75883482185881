//@ts-nocheck
import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import { IndividualInputsFormData } from "../index";
import Step from "src/components/MultiStepForm/Step";
import DateInput from "src/components/inputs/DateInput";
import {
  dateInTheFuture,
  formatIsoDateFromString,
  fromStepDataToStringDate,
  olderThan18,
  validDate
} from "src/utils/helpers/dates";
import { validateBirth } from "src/utils/helpers/validations";
import { SegmentEvents } from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";
import { updateSessionSuccess } from "../../../../store/actions/auth";

function BirthDateData({
                         formData,
                         nextStep,
                         prevStep,
                         saveData,
                         progress
                       }: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const dispatch = useDispatch()
  const session = useSelector(({ auth }: RootState) => auth.session) as Session;
  const { segmentTrack, segmentIdentifyUser } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [stepData, setStepData] = useState(
    fromStepDataToStringDate(formData?.birthDate) ?? ""
  );
  const userType = useSelector<any, "CoOwner" | "CoBuyer" | null>(state => state.auth.userType);

  const isInvalidDate =
    stepData.length === 8 &&
    (!validDate(stepData) || dateInTheFuture(stepData));

  const isMinor = stepData.length === 8 && !olderThan18(stepData);

  const onNextClick = () => {
    if (validateBirth(stepData)) {
      dispatch(updateSessionSuccess({ ...session, user: { ...session?.user!, dateOfBirth: stepData  } }));
      segmentIdentifyUser({ ...session, user: { ...session?.user, dateOfBirth: stepData  } } );
      segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
        step: "What’s your birth date?",
        data: stepData,
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      saveData({ birthDate: formatIsoDateFromString(stepData) });
      console.log("BirthDateData - userType", userType);
      if (userType === "CoBuyer") {
        //jump to page 26.2
        console.log("jumping to page 26.2");
        nextStep(5);
      } else {
        //regular flow for co-owner
        nextStep();
      }
    }
  };

  return (
    <Step
      title="What’s your birth date?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!validateBirth(stepData)}
      onBackClick={() => prevStep()}
      progress={progress}
    >
      <DateInput
        id="birthDate"
        label="Date of birth"
        value={stepData}
        onChange={setStepData}
        name="birthDate"
        errorMessage={
          isInvalidDate
            ? "Invalid date, format must be MM/DD/YYYY"
            : isMinor
              ? "As per the Terms of use, use of this platform is reserved for folks aged 18 years old and over."
              : undefined
        }
        isValid={isInvalidDate ? false : !isMinor}
      />
    </Step>
  );
}

export default BirthDateData;
