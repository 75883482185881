import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import Step from "src/components/MultiStepForm/Step";

const UserTypeOptions = [
    { id: "CoOwner", value: "CoOwner" },
    { id: "CoBuyer", value: "CoBuyer" },
  ];

function UserType({
    formData,
    nextStep,
    prevStep,
    saveData,
    progress,
  }: MultiStepFormChildrenProps<SignUpFormData>) {
    //const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
    const [stepData, setStepData] = useState(formData?.UserType || "");
    const [failed, setFailed] = useState(false);    

    const onNextClick = () => {
        saveData({ UserType: stepData });
        nextStep(12)
    }

    return (
        <Step
            title="Current status?"
            description="Are you a CoBuyer or CoOwner"
            onNextClick={onNextClick}
            nextText="Next"
            onBackClick={() => prevStep()}
            isButtonDisabled={!stepData}
            progress={progress}
            failed={failed}
            failedDescription="During our beta phase, we're focused on helping smaller groups. "
            className="bottom-not-fixed"
        >
        <RadioButtonGroup
            options={UserTypeOptions}
            selectedId={stepData}
            onChange={(value: string) => setStepData(value)}
        />
        </Step>
    );
}


export default UserType;
