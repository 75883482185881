import React, { useEffect } from "react";
import { RootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { setFormState } from "src/store/actions/global";

export type MultiStepFormChildrenProps<T> = {
  formStep: number;
  formData: T;
  nextStep: (step?: number) => void;
  prevStep: (step?: number) => void;
  saveData: (data: any) => void;
  progress: number;
};

type MultiStepFormProps<T> = {
  children(
    MultiStepFormChildrenProps: MultiStepFormChildrenProps<T>
  ): React.ReactNode;
  totalSteps: number;
  initialFormStep?: number;
  stepsNumbersForProgress?: number[];
  formName: string;
};

function MultiStepForm<T>({
  children,
  totalSteps,
  initialFormStep = 1,
  stepsNumbersForProgress = [],
  formName,
}: MultiStepFormProps<T>) {
  useEffect(() => {
    formNameStore !== formName &&
      dispatch(
        setFormState({ formName, formStep: initialFormStep, formData: {} })
      );
  }, []);
  const dispatch = useDispatch();
  const {
    formStep = initialFormStep,
    formData,
    formName: formNameStore,
  } = useSelector((state: RootState) => state.global.stepData);

  const nextStep = (step = formStep + 1) => {
    formStep <= totalSteps && dispatch(setFormState({ formStep: step }));
  };

  const prevStep = (step = formStep - 1) => {
    formStep > 1 && dispatch(setFormState({ formStep: step }));
  };

  const saveData = (data: any) => {
    dispatch(setFormState({ formData: data }));
  };

  const getProgress = (actualStep: number) => {
    const totalProgress = stepsNumbersForProgress.length;
    const index = stepsNumbersForProgress.indexOf(actualStep);
    if (index === -1) {
      return 0;
    }
    return (100 * (index + 1)) / totalProgress;
  };

  return (
    <>
      {children &&
        children({
          formStep,
          formData,
          nextStep,
          prevStep,
          saveData,
          progress: getProgress(formStep),
        })}
    </>
  );
}

export default MultiStepForm;
