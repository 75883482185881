import { useContext, useState, useEffect } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import Storage from "src/services/LocalStorage";
import { SignUpFormData } from "..";
import AddressInput, {
  initialAddressData,
} from "src/components/inputs/AddressInput";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";

function CoOwnedHomeAddressData({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(
    formData.coOwnedHomeAddress || initialAddressData
  );

  useEffect(() => {
    if (formData?.UserType === "CoBuyer") {
      nextStep();
    }
  }, [formData, nextStep]);   

  const { address, city, state, zipCode, country } = stepData;
  const isDataCompleted = address && city && state && zipCode && country;

  const onNextClick = () => {
    if (isDataCompleted) {
      const { zipCode: zip_code, ...segmentData } = stepData
      segmentTrack(SegmentEvents.LeaderPreSignUpStepSubmitted, {
        step: "Address of the co-owned home?",
        data: { zip_code, ...segmentData },
        status: SegmentResponseStatus.Success,
      });

      Storage.setItem("coOwnedHomeAddress", {
        street_address: address,
        locality: city,
        region: state,
        postal_code: zipCode,
        country: country,
      });
      saveData({ coOwnedHomeAddress: stepData });
      nextStep();
    }
  };

  return (
    <Step
      title="Address of the co-owned home?"
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!isDataCompleted}
      onBackClick={() => prevStep()}
      progress={progress}
      className="bottom-not-fixed"
    >
      <AddressInput
        address={stepData}
        onChange={(address) => setStepData(address)}
      />
    </Step>
  );
}

export default CoOwnedHomeAddressData;
