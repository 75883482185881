import { Session } from "src/services/Api/types";
import { Page, PagePermission, Permissions } from "src/utils/helpers/permissions";
import {
  ACCESS_DASHBOARD,
  ACCESS_DASHBOARD_REJECTED,
  ACCESS_DASHBOARD_SUCCESS,
  ACCESS_GOVERNANCE,
  ACCESS_GOVERNANCE_REJECTED,
  ACCESS_GOVERNANCE_SUCCESS,
  AccessDashboardAction,
  AccessDashboardRejectedAction,
  AccessDashboardSuccessAction,
  AccessGovernanceAction,
  AccessGovernanceRejectedAction,
  AccessGovernanceSuccessAction,
  LOGOUT,
  LOGOUT_REJECTED,
  LOGOUT_SUCCESS,
  LogoutAction,
  LogoutRejectedAction,
  LogoutSuccessAction,
  SET_USER_TYPE,
  UPDATE_PERMISSIONS,
  UPDATE_SESSION_SUCCESS,
  UpdatePermissionsAction,
  UpdateSessionSuccessAction
} from "../actions/auth";

export const initialState: {
  isAuth: boolean;
  permissions?: Permissions;
  page?: Page;
  permission?: PagePermission;
  session?: Session | null;
  validateEmailError: boolean;
  isLoggingOut: boolean;
  isAccessingDashboard: boolean;
  isAccessingGovernance: boolean;
  userType?: "CoOwner" | "CoBuyer" | null;  // Agregamos userType
} = {
  isAuth: false,
  validateEmailError: false,
  isLoggingOut: false,
  isAccessingDashboard: false,
  isAccessingGovernance: false,
  userType: null  // Inicializamos userType
};

export interface SetUserTypeAction {
  type: typeof SET_USER_TYPE;
  payload: "CoOwner" | "CoBuyer";
}

export function authReducer(
  state = initialState,
  action:
    | UpdateSessionSuccessAction
    | LogoutAction
    | LogoutSuccessAction
    | LogoutRejectedAction
    | UpdatePermissionsAction
    | AccessDashboardAction
    | AccessDashboardSuccessAction
    | AccessDashboardRejectedAction
    | AccessGovernanceAction
    | AccessGovernanceSuccessAction
    | AccessGovernanceRejectedAction
    | SetUserTypeAction
) {
  switch (action.type) {
    case UPDATE_SESSION_SUCCESS:
      return {
        ...state,
        isAuth: true,
        session: {
          ...action.payload,
          group: { ...action.payload?.group, relations: action.payload?.groupRelationships }
        }
      };
    case UPDATE_PERMISSIONS:
      return { ...state, ...action.payload };
    case LOGOUT:
      return { ...state, isLoggingOut: true };
    case LOGOUT_SUCCESS:
    case LOGOUT_REJECTED:
      return { ...state, isLoggingOut: false };
    case ACCESS_DASHBOARD:
      return { ...state, isAccessingDashboard: true };
    case ACCESS_DASHBOARD_SUCCESS:
    case ACCESS_DASHBOARD_REJECTED:
      return { ...state, isAccessingDashboard: false };
    case ACCESS_GOVERNANCE:
      return { ...state, isAccessingGovernance: true };
    case ACCESS_GOVERNANCE_SUCCESS:
    case ACCESS_GOVERNANCE_REJECTED:
      return { ...state, isAccessingGovernance: false };
    case SET_USER_TYPE:
      return { ...state, userType: action.payload };
    default:
      return state;
  }
}
