//@ts-nocheck
import { useContext, useState } from "react";
import TextInput from "src/components/inputs/TextInput";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import { GroupCreationFormData } from "..";
import Select from "src/components/inputs/Select";
import { GroupInvitationMember } from "src/services/Api/types";
import Api from "src/services/Api";
import { UserRelationships } from "src/services/Api/enums";
import { validateEmail, validateName } from "src/utils/helpers/validations";
import { updatePermissions, updateSessionSuccess } from "src/store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import IconTextButton from "src/components/buttons/IconTextButton";
import { OnboardingPermission } from "src/utils/helpers/permissions";
import { RootState } from "src/store/reducers";
import { userRelationshipOptions } from "src/pages/Onboarding/GroupStatus/helper";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { SegmentEvents } from "../../../../components/shared/enums";
import { captureMessage } from "@sentry/react";

const initialCoOwner: GroupInvitationMember = {
  firstName: "",
  email: "",
  userRelationship: ""
};

function InviteCoOwners({
                          formData,
                          saveData
                        }: MultiStepFormChildrenProps<GroupCreationFormData>) {
  const { segmentTrack, segmentGroup, marshalSegmentGroup } = useContext(AnalyticsContext) as AnalyticsContextType;
  const { session } = useSelector(({ auth }: RootState) => auth);
  const userType = useSelector<any, "CoOwner" | "CoBuyer" | null>(state => state.auth.userType);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [stepData, setStepData] = useState(
    formData?.coOwners || [{ ...initialCoOwner }]
  );

  const onNextClick = async () => {
    if (isFormValid()) {
      setIsLoading(true);
      const members = stepData.map((member): GroupInvitationMember => {
        return {
          firstName: member.firstName,
          email: member.email,
          userRelationship: member.userRelationship as UserRelationships
        };
      });
      try {
        const { data } = await Api.createGroupOnboarding({ members,usertype: userType });
        saveData({ coOwners: stepData });
        setIsLoading(false);

        for (let i = 0; i < members.length; i++) {
          const member = members[i]
          segmentTrack(SegmentEvents.InviteSent, {
            user_id: session?.user?.id,
            user_to_group_id: data.userToGroupId,
            invitee_first_name: member.firstName,
            invitee_email: member.email
          }, { groupId: data.groupId })
        }

        dispatch(
          updateSessionSuccess({ user: session?.user, group: data.group })
        );
        dispatch(
          updatePermissions({ permission: OnboardingPermission.GROUP_STATUS })
        );
        segmentGroup(data.groupId, marshalSegmentGroup(data.group));
        segmentTrack(SegmentEvents.AccountCreated, {
          user_id: session?.user?.id,
          user_to_group_id: data.userToGroupId
        }, { groupId: data.groupId });

        segmentGroup(data.groupId, marshalSegmentGroup(data.group));
        segmentTrack(SegmentEvents.AccountAddedUser, {
          user_id: session?.user?.id,
          user_to_group_id: data.userToGroupId
        }, { groupId: data.groupId });
      } catch (error) {
        captureMessage(JSON.stringify(error))
        setIsLoading(false);
      }
    }
  };

  const deleteCoOwner = (n: number) =>
    setStepData(stepData?.filter((coOwner, index) => n !== index));

  const addCoOwner = () => {
    if (stepData.length < 4 && !isLoading) {
      const data = [...stepData];
      data.push({ ...initialCoOwner });
      setStepData(data);
    }
  };

  const isFormValid = () =>
    stepData.every(
      (coOwner) =>
        validateName(coOwner.firstName) &&
        validateEmail(coOwner.email) &&
        coOwner.userRelationship
    );

  const showAddCoOwnerButton = stepData.length < 3;

  return (
    <Step
      title={userType === 'CoBuyer' ? 'Invite co-buyers' : 'Invite co-owners'}
      className="invite-co-owners"
      description={userType === 'CoBuyer' ? "Invite others to join your co-buyers group." : "Invite others to join your co-ownership group."}
      onNextClick={onNextClick}
      nextText="Next"
      isButtonLoading={isLoading}
      isButtonDisabled={!isFormValid()}
      hideHeaderOnMobile={true}
    >
      {stepData.map((coOwner, index) => {
        return (
          <div className="co-owner" key={index}>
            <div className="header">
              <h5>{userType === 'CoBuyer' ? 'Co-buyer' : 'Co-owner'} #{index + 2}</h5>{" "}
              {index !== 0 && (
                <button
                  type="button"
                  className={`simple-button ${isLoading ? "disabled" : ""}`}
                  onClick={() => !isLoading && deleteCoOwner(index)}
                >
                  Delete
                </button>
              )}
            </div>
            <TextInput
              id={"firstName"}
              label={userType === 'CoBuyer' ? "Co-buyer's first name" : "Co-owner's first name"}
              name={"firstName"}
              value={coOwner.firstName}
              isDisabled={isLoading}
              onChange={(firstName) => {
                const data = [...stepData];
                data[index].firstName = firstName;
                setStepData(data);
              }}
            />
            <TextInput
              id={"email"}
              label={userType === 'CoBuyer' ? "Co-buyer's email address" : "Co-owner's email address"}
              name={"email"}
              value={coOwner.email}
              isDisabled={isLoading}
              onChange={(email) => {
                const data = [...stepData];
                data[index].email = email;
                setStepData(data);
              }}
            />
            <Select
              id="userRelationship"
              label="Your relationship"
              isDisabled={isLoading}
              onChange={(value) => {
                const data = [...stepData];
                data[index].userRelationship = value;
                setStepData(data);
              }}
              selectedId={stepData[index]?.userRelationship}
              options={userRelationshipOptions}
            />
          </div>
        );
      })}

      {showAddCoOwnerButton && (
        <IconTextButton
          onClick={addCoOwner}
          text={userType === 'CoBuyer' ? "Add another co-buyer" : "Add another co-owner"}
          iconClass="icon-plus"
          isDisabled={isLoading}
        />
      )}
    </Step>
  );
}

export default InviteCoOwners;
