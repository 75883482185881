import { useEffect } from "react";
import MultiStepForm from "src/components/MultiStepForm";
import InitialStep from "src/components/MultiStepForm/InitialStep";
import Storage from "src/services/LocalStorage";
import CoOwnersListedOnTitle from "./steps/CoOwnersTitleData";
import CoOwnedHomeAddressData from "./steps/CoOwnedHomeAddressData";
import CoOwnedHomePurchaseClosingDateData from "./steps/CoOwnedHomePurchaseClosingDateData";
import OneLastThing from "./steps/ConfirmationData";
import { CoOwnedHomeAddressType } from "src/services/Api/types";
import CoOwnersNumber from "./steps/CoOwnersNumber";
import CreateAccount from "./steps/CreateAccount";
import VerifyEmail from "./steps/VerifyEmail";
import VerifyPhone from "./steps/VerifyPhone";
import MobilePhoneNumberData from "./steps/MobilePhoneNumberData";
import UserType from "./steps/UserType";

export type SignUpFormData = {
  UserType: string;
  coOwnersNumber: string;
  coOwnersTitle: string;
  coOwnedHomeAddress: CoOwnedHomeAddressType;
  coOwnedHomePurchaseDate: string;
  confirmation: boolean;
  email: string;
  emailChallengeId: string;
  phoneNumber: string;
  phoneNumberChallengeId: string;
};

function LeaderSignUpForm() {
  useEffect(() => {
    Storage.removeItem("invitationId");
  }, []);

  return (
    <MultiStepForm<SignUpFormData>
      totalSteps={12}
      stepsNumbersForProgress={[12, 3, 4, 5, 6]}
      formName="leaderSignUp"
    >
      {({ ...props }) => {
        switch (props.formStep) {
          case 1:
            return (
              <InitialStep
                title="Let’s make sure"
                boldTitle="we’re a good fit."
                description={(props.formData?.UserType === "CoBuyer") ? "Before creating an account, please tell us about your co-buyer situation." : "Before creating an account, please tell us about your co-ownership situation." }
                primaryButtonText="Get started"
                onPrimaryButtonClick={props.nextStep}
                loginLink={true}
              />
            );           
          case 2:
            return <UserType {...props} />; 
          case 3:
            return <CoOwnersListedOnTitle {...props} />;
          case 4:
            return <CoOwnedHomeAddressData {...props} />;
          case 5:
            return <CoOwnedHomePurchaseClosingDateData {...props} />;
          case 6:
            return <OneLastThing {...props} />;
          case 7:
            return (
              <InitialStep
                title="Fantastic! Now"
                boldTitle="let’s set up your account."
                description={(props.formData?.UserType === "CoBuyer") ? "After setting up your account, you'll be able to invite your co-buyers and create a co-buyers group on CoBuy." : "After setting up your account, you'll be able to invite your co-owner(s) and create a co-ownership group on CoBuy." }
                primaryButtonText="Next"
                onPrimaryButtonClick={props.nextStep}
                iconClass="icon-check-circled"
              />
            );
          case 8:
            return <CreateAccount {...props} />;
          case 9:
            return <VerifyEmail {...props} />;
          case 10:
            return <MobilePhoneNumberData {...props} />;
          case 11:
            return <VerifyPhone {...props} />;
          case 12:
            return <CoOwnersNumber {...props} />;                        
          default:
            break;
        }
      }}
    </MultiStepForm>
  );
}

export default LeaderSignUpForm;
