import { useContext, useState } from "react";
import RadioButtonGroup from "src/components/inputs/RadioButtonGroup";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import {
  SegmentEvents,
  SegmentResponseStatus,
} from "src/components/shared/enums";
import { SignUpFormData } from "src/pages/Onboarding/signUp/Leader";
import { CoOwnersQuantity } from "src/services/Api/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";

const CoOwnersQuantityOptions = [
  { id: CoOwnersQuantity.Two, value: "2 people" },
  { id: CoOwnersQuantity.Three, value: "3 people" },
  { id: CoOwnersQuantity.Four, value: "4 people" },
  { id: CoOwnersQuantity.Five, value: "5 or more" },
];

function CoOwnersNumber({
  formData,
  nextStep,
  prevStep,
  saveData,
  progress,
}: MultiStepFormChildrenProps<SignUpFormData>) {
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType
  const [stepData, setStepData] = useState(formData?.coOwnersNumber || "");
  const [failed, setFailed] = useState(false);

  const onNextClick = () => {
    if (stepData) {
      const isCandidateQualified = 2 <= +stepData && +stepData <= 4;

      segmentTrack(SegmentEvents.LeaderPreSignUpStepSubmitted, {
        step: "Number of co-owners (including you)?",
        data: stepData,
        status: isCandidateQualified
          ? SegmentResponseStatus.Success
          : SegmentResponseStatus.Error,
      });

      if (isCandidateQualified) {
        saveData({ coOwnersNumber: stepData });
        nextStep(3);
      } else {
        setFailed(true);
      }
    }
  };

  return (
    <Step
      title={(formData?.UserType === "CoOwner") 
        ? "Number of co-owners (including you)?" 
        : "Number of co-buyers (including you)?"}
      description="Include all who share ownership, no matter where they live."
      onNextClick={onNextClick}
      nextText="Next"
      onBackClick={() => prevStep(2)}
      isButtonDisabled={!stepData}
      progress={progress}
      failed={failed}
      failedDescription="During our beta phase, we're focused on helping smaller groups. "
      className="bottom-not-fixed"
    >
      <RadioButtonGroup
        options={CoOwnersQuantityOptions}
        selectedId={stepData}
        onChange={(value: string) => setStepData(value)}
      />
    </Step>
  );
}

export default CoOwnersNumber;
