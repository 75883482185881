//@ts-nocheck
import { useContext, useState } from "react";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import Step from "src/components/MultiStepForm/Step";
import { IndividualInputsFormData } from "..";
import AddressInput, { initialAddressData } from "src/components/inputs/AddressInput";
import { SegmentEvents } from "src/components/shared/enums";
import { AnalyticsContext, AnalyticsContextType } from "../../../../components/Analytics";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/reducers";
import { Session } from "../../../../services/Api/types";
import { updateSessionSuccess } from "../../../../store/actions/auth";
import { updatePermissions } from "../../../../store/actions/auth";
import { OnboardingPermission , Page} from "../../../../utils/helpers/permissions";

function AddressData({
                       formData,
                       nextStep,
                       prevStep,
                       saveData,
                       progress
                     }: MultiStepFormChildrenProps<IndividualInputsFormData>) {
  const dispatch = useDispatch()
  const session = useSelector(({ auth }: RootState) => auth.session) as Session;
  const { segmentTrack, segmentIdentifyUser } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [stepData, setStepData] = useState(
    formData?.coOwnedHomeData || initialAddressData
  );
  const { address, city, state, zipCode, country } = stepData.address;
  const userType = useSelector<any, "CoOwner" | "CoBuyer" | null>(state => state.auth.userType);

  const onNextClick = () => {
    if (isDataCompleted) {
      //const { live: occupant, address: { zipCode: zip_code, ...addressData } } = stepData;

      // 1. Preparar los datos mínimos necesarios para la sesión
      const updatedSession = {
        ...session,
        user: {
          ...session?.user,
          // Datos de dirección
          address: { ...session?.user?.address, ...stepData.address },
          // Datos necesarios para permisos
          isIdentityVerified: false,
          hasStartedCoAgreement: false,
          hasAccessDashboard: false,
          isSignupCompleted: true
        },
        group: {
          ...session?.group,
          allMembersVerified: false,
          isSubscribed: false,
          users: [],  // Asegurarnos de que users sea un array vacío
          invitations: [],  // Agregar invitations como array vacío
          id: session?.group?.id || null  // Mantener el ID del grupo si existe
        }
      };

       // 2. Guardar en Redux
       dispatch(updateSessionSuccess(updatedSession));

       // 3. Actualizar Analytics
      segmentIdentifyUser(updatedSession);
      segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
        step: "Where do you live?",
        data: stepData,
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });

      /*
      dispatch(updateSessionSuccess({ ...session, user: { ...session?.user!, address: { ...session?.user?.address!, ...stepData.address} } }))
      segmentIdentifyUser({ ...session, user: { ...session?.user!, address: { ...session?.user?.address!, ...stepData.address} } } );
      segmentTrack(SegmentEvents.UserDetailsStepSubmitted, {
        step: "Where do you live?",
        data: { occupant, ...{ address: { zip_code, ...addressData } } },
        user_to_group_id: session?.userToGroupId
      }, { groupId: session?.group?.id });
      */
      saveData({ coOwnedHomeData: stepData });
      //nextStep();
      console.log("AdressData userType", userType);
      if (userType === "CoBuyer") {
        // console.log('Session before navigation:', updatedSession); // Debug
        // dispatch(
        //   updatePermissions({
        //     page: Page.ONBOARDING,
        //     permission: OnboardingPermission.GROUP_CREATION,
        //   })
        // );
        nextStep();
      } else {
        nextStep();   // Flujo normal para CoOwner
      }
    }
  };
  const isDataCompleted = address && city && state && zipCode && country;
  

  const handleBackClick = () => {
    if (userType === "CoBuyer") {
      prevStep(3);  // Volver a BirthDateData (caso 3 en el switch)
    } else {
      prevStep();   // Flujo normal para CoOwner
    }
  };

  return (
    <Step
      //title="Where do you live?"
      title={userType === "CoBuyer" ? "Where do you currently live?" : "Where do you live?"}
      onNextClick={onNextClick}
      nextText="Next"
      isButtonDisabled={!isDataCompleted}
      progress={progress}
      onBackClick={handleBackClick} 
      className="bottom-not-fixed"
    >
      <AddressInput
        address={stepData.address}
        onChange={(address) => setStepData({ ...stepData, address })}
      />
    </Step>
  );
}

export default AddressData;
