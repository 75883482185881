import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { Path } from "src/components/routing/routes";
import Api from "src/services/Api";
import React, { useContext, useEffect, useState } from "react";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import Storage from "src/services/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { setFormState, setFullPageLoading, setMainHeader } from "src/store/actions/global";
import { validateEmail } from "src/utils/helpers/validations";
import Logo from "src/components/Logo";
import { SignUpFormData } from "..";
import { MultiStepFormChildrenProps } from "src/components/MultiStepForm";
import { decodeJWT, getFormatAddress } from "src/utils/helpers/helpers";
import Fail from "src/components/Fail";
import { SegmentEvents, SegmentResponseStatus, SegmentSignUpMethod } from "src/components/shared/enums";
import { PUBLIC_RECAPTCHA_SITE_KEY } from "src/constants";
import { AnalyticsContext, AnalyticsContextType } from "../../../../../components/Analytics";
import { captureMessage } from "@sentry/react";
import { setUserType } from "src/store/actions/auth";
import { RootState } from "src/store/reducers";

function CreateAccount({
                         formStep,
                         formData: { coOwnedHomePurchaseDate, coOwnedHomeAddress, UserType },
                         nextStep,
                         saveData
                       }: MultiStepFormChildrenProps<SignUpFormData>) {
  let location = useLocation();
  const { segmentTrack } = useContext(AnalyticsContext) as AnalyticsContextType;
  const [failed, setFailed] = useState(false);
  const [isValidReCaptcha, setIsValidReCaptcha] = useState(false);
  const [reCaptchaError, setReCaptchaError] = useState<string | null>(null);
  const recaptchaRef = React.createRef() as React.RefObject<ReCAPTCHA>;
  const userType = useSelector((state: { auth: { userType: "CoOwner" | "CoBuyer" | null } }) => {
    return state.auth.userType;
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    watch
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (UserType) {
      dispatch(setUserType(UserType as "CoOwner" | "CoBuyer"));
    }
  }, [UserType, dispatch]);

  useEffect(() => {
    dispatch(
      setMainHeader({
        hide: !failed,
        hideBottomLine: failed
      })
    );
  }, [failed]);

  const onGoogleSuccess = async (response: CredentialResponse) => {
    dispatch(setFullPageLoading(true));
    const invitationId = Storage.getItem("invitationId");
    const { credential: socialToken } = response;
    const { email, sub: googleId } = decodeJWT(socialToken!);

    try {
      const {
        data: { emailChallengeId }
      } = await Api.signUpVerifyGoogle(
        invitationId
          ? { email, invitationId, socialToken: socialToken!, googleId }
          : {
            email,
            address: getFormatAddress(coOwnedHomeAddress),
            closingPurchaseDate: coOwnedHomePurchaseDate,
            socialToken: socialToken!,
            googleId
          }
      );
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Create a new account",
        data: { email, method: SegmentSignUpMethod.Google },
        status: SegmentResponseStatus.Success
      });
      saveData({ email, emailChallengeId });
      nextStep(formStep + 2);
    } catch (error) {
      captureMessage(JSON.stringify(error))
      segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
        step: "Create a new account",
        data: { email, method: SegmentSignUpMethod.Google },
        status: SegmentResponseStatus.Error
      });
      setFailed(true);
    } finally {
      dispatch(setFullPageLoading(false));
    }
  };

  const onSubmit = async ({ email }: any) => {
    if (isFormValid(email)) {
      const invitationId = Storage.getItem("invitationId");
      try {
        const dummy_address = {
          "street_address": "1585 Charleston Rdxx",
          "locality": "Mountain View",
          "region": "CA",
          "postal_code": "94043",
          "vicinity": "Hamilton Township",
          "county": "Notts",
          "country": "USA"
      }
        const {
          data: { emailChallengeId }
        } = await Api.signUpVerifyEmail(
          invitationId
            ? { email, invitationId }
            : {
              email,
              address: (UserType == 'CoOwner') ? getFormatAddress(coOwnedHomeAddress) : dummy_address,
              closingPurchaseDate: (UserType == 'CoOwner') ? coOwnedHomePurchaseDate : '2022-01-01T00:00:00.000Z',
              usertype: UserType
            }
        );
        segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
          step: "Create a new account",
          data: { email, method: SegmentSignUpMethod.Email },
          status: SegmentResponseStatus.Success
        });
        saveData({ email, emailChallengeId });
        nextStep();
      } catch (error: any) {
        console.log(error);
        setFailed(true);
        captureMessage(JSON.stringify(error))
        segmentTrack(SegmentEvents.UserSignupStepSubmitted, {
          step: "Create a new account",
          data: { email, method: SegmentSignUpMethod.Email },
          status: SegmentResponseStatus.Error
        });
      }
    }
  };

  const isFormValid = (email: string) => {
    return validateEmail(email) && isValidReCaptcha;
  };

  const onReCaptchaChange = async (value: any | null) => {
    console.log(value.length);
    if(value && value.length > 2560){
      setReCaptchaError('ReCaptcha token is too long. Please try again.');
      setIsValidReCaptcha(false);
      if(recaptchaRef?.current){
        recaptchaRef.current.reset();
      }
      return;
    }
    try {
      const response = await Api.validateReCaptcha(value);
      setIsValidReCaptcha(!!response?.data?.validRecaptcha);
      setReCaptchaError(null);
    } catch (error) {
      console.log(error);
      setIsValidReCaptcha(false);
      setReCaptchaError('ReCaptcha validation failed. Please try again.');
      if(recaptchaRef?.current){
        recaptchaRef.current.reset();
      }
    }
  };

  const onReCaptchaError = () => {
    setIsValidReCaptcha(false);
    setReCaptchaError('ReCaptcha validation failed. Please try again.');
    if(recaptchaRef?.current){
      recaptchaRef.current.reset();
    }
  };

  return failed ? (
    <Fail
      title="Something went wrong, please try again later."
      buttonText="Ok"
      onButtonClick={() =>
        dispatch(setFormState({ formStep: 1, formData: {} }))
      }
    />
  ) : (
    <>
      <div className="sign-up-background" />
      <div className="content-wrapper login-wrapper">
        <Logo />
        <div className="text-center">
          <h3>Create a new account</h3>
        </div>
        <GoogleLogin
          onSuccess={onGoogleSuccess}
          onError={() => console.log('fail')}
          useOneTap={false}
          width="360px"
          logo_alignment="center"
          auto_select={false}
          text="signup_with"
          context="signup"
          shape={'rectangular'}
        />
        <div className="or-separator">
          <span>Or</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`text-input-container ${isSubmitting ? "disabled" : ""}`}
          >
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Email address"
              {...register("email")}
              required
            />
          </div>
          <p className="disclaimer-text">
            <strong>Honesty Pledge:</strong> I will provide honest answers to
            all of the questions in this assessment.
          </p>
          <div className="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={PUBLIC_RECAPTCHA_SITE_KEY}
              onChange={onReCaptchaChange}
              onExpired={() => {
                setIsValidReCaptcha(false);
                setReCaptchaError(null);
                if(recaptchaRef?.current){
                  recaptchaRef.current.reset();
                }
              }}
              onErrored={onReCaptchaError}
              hl="en-us"
            />
          </div>
          {reCaptchaError && <div style={{
            color: 'red',
            fontSize: '12px',
            marginTop: '5px',
            marginLeft: '51px'
          }}>{reCaptchaError}</div>}
          <div className="button-center">
            <button
              type="submit"
              className={isSubmitting ? "loading-button" : ""}
              disabled={!isFormValid(watch("email"))}
            >
              {isSubmitting ? "" : "Sign up with email"}
            </button>
          </div>
        </form>
        <p className="dont-have-account">
          Already have an account?{" "}
          <Link className={`${isSubmitting ? "disabled" : ""}`} to={Path.Login}>
            Log in
          </Link>
        </p>
        <p className="legal-footer">
          By creating an account, I confirm I have read and agree to the{" "}
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to={Path.Terms}
            state={{ from: location }}
          >
            Terms of use
          </Link>{" "}
          and{" "}
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to={Path.PrivacyPolicy}
            state={{ from: location }}
          >
            Privacy policy
          </Link>
          .
        </p>
        <p className="legal-footer login">
          <Link
            className={`${isSubmitting ? "disabled" : ""}`}
            to="https://www.cobuy.io/disclaimer"
            target="_blank"
          >
            Disclaimer
          </Link>
        </p>
      </div>
    </>
  );
}

export default CreateAccount;
